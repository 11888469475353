@import 'primer';
@import 'highlight-syntax';


// If a user adds a custom font, this component will stop it from bleeding into GitHub components:
.github-component {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}

.repo-language-color {
    filter: brightness(125%) !important;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    position: relative;
    top: 2px;
    width: 12px;
}

.emoji {
  width: 18px;
  height: auto;
  vertical-align: middle;
}

.article {
  h1, h2, h3, h4,
  .highlight {
    margin-bottom: 16px;
  }

  blockquote {
    color: #6a737d;
    border-left: 2px solid #959da5;
    padding-left: 16px;
    margin-bottom: 16px;
  }

  ul, ol {
    padding-left: 32px;
    margin-bottom: 16px;
  }

  li ul, li ol {
    padding-left: 16px;
    margin-bottom: 0px;
  }
}

.min-height-full {
  min-height: 100vh;
}

.img-preview-link {
  text-decoration: none !important;
}

.img-preview-img {
  max-width: 150px;
  max-height: 150px;
}

.banner-main {
  background-position:center;
  background-size:cover;
  min-width: 100%;
  position: relative;
}
.banner-label {
  background: #3338;
  padding: 4px;
  border-radius: 4px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.banner-div {
  font-size: 20px;
  width: 100%;
}
.banner-l {
  font-size: 20px;
  text-align: left;
  padding-left: 40px;
  padding-right: 80px;
}
.banner-r {
  font-size: 20px;
  text-align: right;
  padding-left: 80px;
  padding-right: 40px;
}

@media (max-width: 400px) {
  .banner-l {
    padding-left: 10%;
    padding-right: 20%;
  }
  .banner-r {
    padding-right: 10%;
    padding-left: 20%;
  }
}

body {
  min-width: 100%;
  min-height: 100vh;
  background: #333;
  color: #eeeeee;
  text-align: center;
}

.brick-container {
  width: 96%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-around;
  position: relative;
}

.brick-exp {
  display: none;
  order: 0;
  background: #555;
  width: 100%;
  min-height: 200px;
  margin: 4px;
}
.brick-1 {
  font-size: 20pt;
  width: 16.666666%;
  position: relative;
  margin: 0;
  padding: 0;
}

.brick-inner-container {
  display: flex;
  flex-wrap: wrap-reverse;
  position: relative;
  width: 100%;
}

.brick-only-pics {
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}

.brick-pics {
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 12px;
  width: 50%;
}

.brick-text {
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 24px;
  width: 50%;
  text-align: justify;
}

#marker { order: 6; }
@media (max-width: 1200px) {
  .brick-1 { width: 20%; }
  #marker { order: 5; }
}
@media (max-width: 1000px) {
  .brick-1 { width: 25%; }
  #marker { order: 4; }
}
@media (max-width: 800px) {
  .brick-1 { width: 33.333333%; }
  #marker { order: 3; }
  .brick-text, .brick-pics {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .brick-1 { width: 50%; }
  #marker { order: 2; }
}
@media (max-width: 400px) {
  .brick-1 { width: 100%; }
  #marker { order: 1; }
}

.brick-2 {
  width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;
}
.brick-3 {
  margin: 4px;
  background-position: center;
  background-size: 100%;
  position: absolute; top: 0; bottom: 0; left: 0; right: 0;
  cursor: pointer;
}
.v-center {
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%);
}

.close {
  height: 50px;
  width: 50px;
  position: absolute;
  cursor: pointer;
  text-decoration: none;
  z-index: 99;
  right: 0;
  top: 0;
  text-align: left;
}
.close:after, .close:before {
  position: absolute;
  margin-top: 22px;
  margin-left: 14px;
  content: "";
  height: 3px;
  background: white;
  width: 23px;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.close:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

li > a {
  color: white;
}
